import "./App.css";

const telegram = "https://t.me/CYCLESOLANA";
const twitter = "https://twitter.com/solonacycle";
const twitterPost = "https://twitter.com/rajgokal/status/1572759044733149185";
const birdeye = "https://birdeye.so/token/AcdPbD9z9wMjHrXqUpx3Wcaoo4L7rb2QbSCUiARgKC7z?chain=solana"
const dexTool = "https://www.dextools.io/app/en/solana/pair-explorer/3Ugw2b4NbRMA5uwHxUbqkYSAaZNhM4qwDKLgckneXPW4";
const dexToolFrame = "https://dexscreener.com/solana/3Ugw2b4NbRMA5uwHxUbqkYSAaZNhM4qwDKLgckneXPW4?embed=1&theme=dark"

function App() {
  return (
    <div id="main">
      <div className="inner">
        <div id="container02" className="container columns">
          <div className="wrapper">
            <div className="inner">
              <div>
                <h1 id="text01">$CYCLE</h1>
                <p id="text02">
                  "Next $CYCLE is the solana $CYCLE.
                  <br />
                  I don't make the rules"
                  <br />
                  - Raj - Solana Co-founder
                </p>
                <ul id="buttons02" className="buttons">
                  <li>
                    <a href={telegram} className="button n01">
                      <svg>
                        <use xlinkHref="#icon-96fc68818d10374399c6dcf28fe98f75" />
                      </svg>
                      <span className="label">Telegram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={twitter}
                      className="button n02"
                    >
                      <svg>
                        <use xlinkHref="#icon-31b8880d36499db40d4e47546c4763f3" />
                      </svg>
                      <span className="label">Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={dexTool}
                      className="button n03"
                    >
                      <svg>
                        <use xlinkHref="#icon-7a66fac84dc5d9fb5fafce395a384d40" />
                      </svg>
                      <span className="label">DexTools</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={birdeye}
                      className="button n04"
                    >
                      <svg>
                        <use xlinkHref="#icon-be848c9691a8e3f7630e3908e426bf9f" />
                      </svg>
                      <span className="label">BirdEye</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div style={{marginTop: '44px',display: 'flex', alignItems: 'center'}}>
                    <iframe title="twitter" height={350} width={550} src={`https://twitframe.com/show?url=${twitterPost}`}></iframe>
              </div>
            </div>
            <div style={{marginTop: '100px'}}>
              <iframe style={{width: '100%'}} title="dex" allow="fullscreen" height="800px"  src={dexToolFrame}></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
